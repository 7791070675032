// if (process.env.NODE_ENV === 'production') {
//   window.console.log = function () {};
// }
const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  authApiUrl: process.env.REACT_APP_AUTH_API_URL,
  apiUrlVisual: process.env.REACT_APP_API_URL_VISUALIZATION,
  routes: {
    root: '/',
    error: '/error',
    recoverPassword: '/recover-password',
    verify: '/verify',
    createPassword: '/create-password',

    accountSettings: '/settings',
    embdeding: {
      default: '/auth/embed',
    },
    qca: {
      askQuestion: '/qca/ask',
      feedback: '/qca/feedback',
      dashboard: '/qca/dashboard',
      questionHistory: '/qca/history',
      guide: '/qca/guide',
      contactUs: '/qca/contact-us',
      configurationRequest: '/qca/confguration-request',
      // users: "/qca/users",
    },
    customerAdmin: {
      dashboard: '/admin/dashboard',
      users: '/admin/users',
      datasource: '/admin/datasource',
      questions: '/admin/questions',
    },
  },
  answerStatus: {
    ParserError: 'ParserError',
    ClarificationRequired: 'ClarificationRequired',
    DelError: 'DelError',
    ClarificationCancel: 'ClarificationCancel',
    OK: 'OK',
  },
  userTypes: {
    quarrioAdmin: 1,
    appUser: 2,
    customerAdmin: 3,
    qaAdmin: 6,
    tester: 7,
  },
  graphOutputType: {
    Graph: 'Graph',
    BarChart: 'BarChart',
    LineChart: 'LineChart',
    PieChart: 'PieChart',
    BubbleChart: 'BarChart',
    GanttChart: 'GanttChart',
    ScatterChart: 'GanttChart',
  },
  pinnedTypes: {
    table: 'table',
    visualization: 'visualization',
  },
  testSetStatus: {
    Pass: 'Pass',
    Fail: 'Fail',
    NotTestedNoPrevResponse: 'Not Tested - No Previous Response',
    FailNoCurrentResponse: 'Fail - No Current Response',
    FailNoParserResponse: 'Fail - No Parser Response',
  },
  testSetStatusUser: {
    Pass: 'Pass',
    Fail: 'Fail',
  },
  feedbackStatus: {
    Backlog: 'Backlog',
    Open: 'Open',
    Reviewed: 'Reviewed',
    CheckingIssue: 'Checking issue',
    Resolved: 'Resolved',
    Unresolved: 'Unresolved',
    QuarrioClosed: 'Quarrio - Closed',
    Closed: 'Closed',
    ReOpened: 'Re-Opened',
  },

  uiSettings: {
    pinnedQuestionLimit: 9,
    appbarHeight: 48,
    drawerWidth: 300,
    conversationDrawerWidth: 380,
    componentLoadAttempts: 3,
    timeoutMessages: 5000,
    defaultAnswerRows: 5,
    rowsPerPageAnswerBoxOptions: [5, 10, 25],
  },

  appSettings: {
    requestTimeoutDuration: 300, // {in seconds}
    theme: {
      light: 'light',
      dark: 'dark',
    },
    app: {
      qca: 'qca',
      customerAdmin: 'customerAdmin',
      qaUtility: 'utility',
      quarrioAdmin: 'quarrioAdmin',
    },
  },

  defaultUserSettings: {
    theme: 'dark',
    showSqlStatus: true,
    showCorrectedQuestion: true,
    showParaphrase: true,
    defaultAnswerRows: 5,
    datasetLimit: 200,
  },
  errors: {
    default: 'Something went wrong please try again later',
    invalidCredential: 'Invalid email or password.',
    passwordResetError: 'This account does not exist',
    unAuthorized: '403! Unautorhorized Access',
    notfound: '404! Page not found',
  },
  messages: {
    passwordChanged: 'Your password has been updated.',
    resetPassword: 'Your password has been updated please signin.',
  },
  userTypesLabel: {
    // 1: "Quarrio Admin",
    2: 'App User',
    3: 'Customer Admin',
    // 6: "QA Admin",
    // 7: "Tester",
  },
  datasourceStatus: {
    connectionValidated: 'connectionValidated',
    organzationUpdated: 'organzationUpdated',
    tablesValidated: 'tablesValidated',
    validationCompleted: 'validationCompleted',
  },
};

export default config;
