/* eslint-disable import/no-cycle */
import config from '../config/index';
import axios from '../config/axios';
import { store } from '../redux/store';

export const getAuthInstance = () => store.getState()?.auth;

export const getCurrentUser = () => {
  const auth = getAuthInstance();
  return auth?.user;
};

export const isUser = () => {
  const auth = getAuthInstance();
  if (auth?.user?.userId && auth?.accessToken) {
    return true;
  }
  return false;
};

export const isAppUser = () => {
  const user = getCurrentUser();
  return user?.typeId === config.userTypes.appUser;
};
export const isCustomerAdmin = () => {
  const user = getCurrentUser();
  return user?.typeId === config.userTypes.customerAdmin;
};
export const isQAUtilityUser = () => {
  const user = getCurrentUser();
  return (
    user?.typeId === config.userTypes.qaAdmin ||
    user?.typeId === config.userTypes.tester
  );
};
export const isQuarrioAdmin = () => {
  const user = getCurrentUser();
  return user?.typeId === config.userTypes.quarrioAdmin;
};
export const isQAUtilityAdmin = () => {
  const user = getCurrentUser();
  return user?.typeId === config.userTypes.qaAdmin;
};
export const isQAUtilityTester = () => {
  const user = getCurrentUser();
  return user?.typeId === config.userTypes.tester;
};

export const login = (creds) =>
  axios.publicPost(`${config.authApiUrl}/auth/login`, creds);

export const refreshTokens = (data) =>
  axios.post(`${config.authApiUrl}/auth/refresh/token`, data);

export const logoutUser = () => {
  const auth = getAuthInstance();
  return axios.post(`${config.authApiUrl}/auth/logout`, {
    accessToken: auth.accessToken,
    refreshToken: auth.refreshToken,
    userId: auth.user.userId,
  });
};
export function changePassword(data) {
  return axios.post(`${config.authApiUrl}/user/change-password`, data);
}
